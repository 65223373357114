.indexedStep {
	color: white;
	width: 35px;
	height: 35px;
	font-size: 18px;
	background-color: rgba(211, 211, 211, 0.8);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.indexedStep.accomplished {
	background-color: #509c35;
}
